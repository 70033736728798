import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Grid, Link, Typography, useTheme } from '@mui/material';
import {
  deleteUploadedImage,
  downloadUploadedImage,
  feedbackImageUpload,
} from 'src/redux/imageUpload/actions';
import { useTranslation } from 'react-i18next';
import { ImageUploadItem } from 'src/types/imageUpload.types';
import ImageListItemView from 'src/coreUI/ImageListItemView/ImageListItemView';
import { insertImageToTable } from 'src/redux/translation/actions';
import { TranslateSelector } from 'src/redux/translation/selector';
import { UserSelector } from 'src/redux/user/selector';

type Props = {
  imageItem: ImageUploadItem;
};

const ImageListComp = ({ imageItem }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation('easy_language');
  const dispatch: any = useDispatch();
  const selectedOutputNode = useSelector(
    (state: any) => TranslateSelector(state).selectedOutputNode,
    shallowEqual
  );

  const userDetails = useSelector(
    (state: any) => UserSelector(state).userDetails,
    shallowEqual
  );

  const handleDownload = (id: string) => {
    dispatch(downloadUploadedImage(id));
  };

  const handleFeedback = (id: string, feedback: 0 | 1 | 2) => {
    dispatch(
      feedbackImageUpload(id, feedback, imageItem.id, imageItem.is_owner)
    );
  };

  const handleDelete = (id: string) => {
    dispatch(deleteUploadedImage(id, imageItem.id, imageItem.is_owner));
  };

  const handleInsertImage = (url: string) => {
    dispatch(insertImageToTable(url));
  };
  return (
    <Grid
      item
      container
      xs={12}
      mb={3}
    >
      <Grid
        item
        xs={12}
        mb={2}
      >
        <Typography
          variant="subtitle1"
          gutterBottom
          textAlign={'left'}
          color={theme.palette.primary.main}
          mb={0}
        >
          {imageItem.is_owner
            ? t('myGallary')
            : imageItem.name === 'Bilder'
            ? `${t('bilder')} ${
                imageItem.owner_email ? `- ${imageItem.owner_email}` : ''
              }`
            : `${imageItem.name}${' '}${
                imageItem.owner_email ? `- ${imageItem.owner_email}` : ''
              }`}
        </Typography>
        {imageItem.license && (
          <Typography
            variant="body2"
            gutterBottom
            textAlign={'left'}
            color={theme.palette.primary.main}
          >
            {t('licenseImage')}
            <br />
            <Link
              href={imageItem.license?.link}
              color="inherit"
              target="_blank"
              sx={{ textWrap: 'wrap' }}
            >
              {imageItem.license?.name}
            </Link>
          </Typography>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          border: `2px solid ${theme.palette.general.lightBlue}`,
          padding: '8px',
          borderRadius: '.3em',
        }}
      >
        <ImageListItemView
          imageItem={imageItem}
          handleDelete={handleDelete}
          handleDownload={handleDownload}
          handleFeedback={handleFeedback}
          handleInsertImage={
            userDetails?.previewFeatures ? handleInsertImage : undefined
          }
          disableInsertImage={selectedOutputNode ? false : true}
        />
      </Grid>
    </Grid>
  );
};

export default ImageListComp;
