import { Grid } from '@mui/material';
import SearchInputComp from 'src/coreUI/searchComp/SearchInputComp';
import ImageUpload from './ImageUpload';
import ImageListComp from './ImageListComp';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { searchUploadedImages } from 'src/redux/imageUpload/actions';
import { UploadImageSelector } from 'src/redux/imageUpload/selector';
import { useTranslation } from 'react-i18next';
import { ImageUploadItem } from 'src/types/imageUpload.types';
// import { updateSelectedOutputNode } from 'src/redux/translation/actions';

type Props = {};

const ImageUploadCompWrapper = (props: Props) => {
  const { t } = useTranslation('easy_language');
  const dispatch: any = useDispatch();

  const imageList = useSelector(
    (state: any) => UploadImageSelector(state).imageList,
    shallowEqual
  );

  const loading = useSelector(
    (state: any) => UploadImageSelector(state).loading,
    shallowEqual
  );

  const handleSubmitSearch = (value: string) => {
    dispatch(searchUploadedImages(value));
    // dispatch(updateSelectedOutputNode(null));
  };

  return (
    <Grid
      item
      container
      xs={12}
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <SearchInputComp
          handleSubmit={handleSubmitSearch}
          loading={loading}
          placeholder={t('searchImage')}
        />
      </Grid>
      {!imageList && (
        <Grid
          item
          xs={12}
        >
          <ImageUpload />
        </Grid>
      )}
      {imageList && (
        <Grid
          item
          xs={12}
        >
          {Object.values(imageList).length === 0 ? (
            <div>{t('noImage')}</div>
          ) : (
            [
              ...Object.values(imageList?.ownerList ?? {}),
              ...Object.values(imageList?.otherList ?? {}),
            ].map(
              (imageItem: ImageUploadItem) =>
                Object.keys(imageItem.images).length > 0 && (
                  <ImageListComp
                    imageItem={imageItem}
                    key={imageItem.id}
                  />
                )
            )
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default ImageUploadCompWrapper;
