import {
  $getRoot,
  $insertNodes,
  $isElementNode,
  LexicalNode,
  $getNodeByKey,
  $setSelection,
  NodeKey,
  ElementFormatType,
  // $applyNodeReplacement,
  // $createTextNode,
} from 'lexical';
import { $generateNodesFromDOM } from '@lexical/html';
import { triggerAlert } from '../../redux/appSetting/actions';
import Sentry from '../../config/sentryConfig';
import { isEmpty } from 'lodash';
import {
  $createTableNodeWithDimensions,
  $createTableCellNode,
  $createTableRowNode,
  // TableNode,
} from '@lexical/table';

import { $insertNodeToNearestRoot } from '@lexical/utils';
import { $createImageNode } from 'src/coreUI/textEditorComp/nodes/ImageNode';
import imageResize from 'image-resize';
// import { $createHeadingNode } from '@lexical/rich-text';
// import { $createLayoutItemNode } from 'src/coreUI/textEditorComp/nodes/LayoutItemNode';

export const editorValueClear = (refEditor: any) => {
  if (refEditor.current) {
    refEditor.current.update(() => {
      $getRoot().clear();
    });
  }
};

export const editorValueUpdate = (text: string, refEditor: any, id: string) => {
  if (refEditor.current) {
    refEditor.current.update(() => {
      // Create a p tag on the fly to check to text format fo lexical
      // if (!text.includes('</p')) {
      text = __checkParagraphValidation(text);
      // }
      text = __checkClassName(text);
      if (!isEmpty(text)) {
        // if (id === 'translation-output') {
        //   splitHTMLContentAndCreateTable(refEditor, text);
        //   return;
        // } else if (id === 'translation-input') {

        __createDomAndUpdate(text, refEditor, id);
        //   return;
        // }
      }
    });
  }
};

// convert text to paragraph and table
export const convertParagraphAndTable = (
  refEditor: any,
  sidebarTab: number
) => {
  refEditor.current.update(() => {
    // get content
    const root = $getRoot();
    let nodes = $getAllNodes(root);
    let tablehaveImage: boolean = false;
    let tableContent: boolean = false;

    // clear the editor

    // check if it is a table and if it have image inside it
    for (let node of nodes) {
      // if table already have image inside it
      if (node.__type === 'image') {
        tablehaveImage = true;
      }

      // if the conent is inside table
      if (node.__type === 'table') {
        tableContent = true;
      }
    }

    if (!tableContent && sidebarTab === 3) {
      // if content not table conver
      __convertFromparagraphToTable(nodes, refEditor);
    } else if (tableContent && !tablehaveImage && sidebarTab !== 3) {
      // if content is table convert
      __convertFromTableToParagraph(nodes);
    }
  });
};

const __convertFromTableToParagraph = (nodes: LexicalNode[]) => {
  // loop over node and extract text from table
  let finalTableNodes: LexicalNode[] = [];
  const rootNode = $getRoot();
  for (let node of nodes) {
    if (
      ['heading', 'paragraph', 'list', 'code', 'quote'].includes(node.__type)
    ) {
      finalTableNodes.push(node);
    }
  }
  rootNode.clear();
  rootNode.append(...finalTableNodes);
};

const __convertFromparagraphToTable = (
  nodes: LexicalNode[],
  refEditor: any
) => {
  let finalTableNodes: LexicalNode[] = [];
  // editorValueClear(refEditor);
  const rootNode = $getRoot();
  for (let node of nodes) {
    if (
      ['heading', 'paragraph', 'list', 'code', 'quote'].includes(node.__type)
    ) {
      const tableNode = $createTableNodeWithDimensions(0, 1);
      if (tableNode) {
        // Create a new row
        const rowNode = $createTableRowNode();

        // Create the second cell text
        const cellNode = $createTableCellNode(0);
        cellNode.append(node);

        // Append cells to the row
        rowNode.append(cellNode);
        // Append row to the table
        tableNode.append(rowNode);
        // Insert the table into the editor

        finalTableNodes.push(tableNode);
        // $insertNodeToNearestRoot(tableNode);
      }
    }
  }
  rootNode.clear();
  rootNode.append(...finalTableNodes);
  rootNode.selectStart();
};

const __checkParagraphValidation = (text: string) => {
  let finalParagraphs = '';
  // Match pattern for the wrapped html
  const regex = new RegExp(
    /([^>])(ul|(h[1-5])|p|code|ol)(\W+)(.*?)<\/\2>/,
    'gm'
  );
  // Replace the match pattern with special sign
  let remainingText = text.replace(regex, '$@');
  // Wrap the missing element with p tag
  let filterText = remainingText.split('$@').filter((item: any) => item !== '');

  let itemValue: { [key: string]: string } = {};
  if (filterText.length > 0) {
    Sentry.captureMessage(
      `Translation job HTML Error format: ${text}`,
      'debug'
    );
  }
  filterText?.forEach((item: string) => {
    if (item !== '<br/>' && item !== '<br>') {
      itemValue = {
        [item]: `<p class="editor-paragraph">${item}</p>`,
        ...itemValue,
      };
    }
  });

  // Replace the matching text with the updated one
  let textValueUpdate = text;

  for (let item in itemValue) {
    textValueUpdate = textValueUpdate.replace(item, itemValue[item]);
  }
  finalParagraphs = finalParagraphs + textValueUpdate;

  return finalParagraphs;
};

export const splitHTMLContentAndCreateTable = (
  refEditor: any,
  text: string
) => {
  const switchValue = localStorage?.getItem(`switchInput-translation-input`);
  if (switchValue === 'off' && !isEmpty(text)) {
    text = text.replaceAll(/<br><br>/g, '</p><p>');
  }
  const splitText = text.split(/(?<=<\/(h1|p|h2|h3|h4|h5|h6|ul|code|ol)>)/g);
  const exclude = [
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'ul',
    'code',
    'img',
  ];
  for (let item of splitText) {
    if (!exclude.includes(item)) {
      __createTable(refEditor, item);
    }
  }
};

const __createTable = (refEditor: any, text: string, column: number = 1) => {
  if (refEditor.current && text !== '<p class="editor-paragraph"><br/></p>') {
    refEditor.current.update(() => {
      // Create new table
      const tableNode = $createTableNodeWithDimensions(0, column);

      if (tableNode) {
        // Create a new row
        const rowNode = $createTableRowNode();

        // Create the second cell text
        const textCellNode = __createTableTextCellNode(text, refEditor);
        // Append cells to the row
        rowNode.append(textCellNode);
        // Append row to the table
        tableNode.append(rowNode);
        // Insert the table into the editor
        $insertNodeToNearestRoot(tableNode);
        // tableNode.select();
      }
    });
  }
};

const __createTableTextCellNode = (text: string, refEditor: any) => {
  // Create the second cell
  const cellNode = $createTableCellNode(0);
  const node = __createHtmlFromText(text, refEditor, 'justify');

  // Create empty text node as a separator
  // const secondParagraphNode = $createParagraphNode();
  // secondParagraphNode.append(
  //   $createTextNode('').setStyle('margin-bottom:1em; display:block;')
  // );
  // secondParagraphNode.setFormat('justify');

  // Insert nodes
  cellNode.append(...node);

  return cellNode;
};

const __createTableImageCellNode = async (
  url: string,
  tableNode: LexicalNode | null,
  refEditor: any
) => {
  refEditor.current.update(() => {
    const cellNode = $createTableCellNode(0);
    const imageNode = $createImageNode({
      altText: 'test image', //todo update this name to be image name
      height: 'fit-content',
      src: url,
      width: 120,
      handleDeleteCell: __handleDeleteFirstCell,
    });
    cellNode.append(imageNode);
    cellNode.setWidth(140);
    if (tableNode) {
      tableNode.append(cellNode, tableNode.getFirstChild());
      resetHighlight(refEditor);
    }
  });
};

export const updateTableColumn = async (
  refEditor: any,
  url: string,
  selectedNode: string
) => {
  if (refEditor.current) {
    let anchorRowNode = null;

    // Resize img
    let res = (await imageResize(url, {
      format: 'png',
      width: 200,
      height: 200,
    })) as string;

    refEditor.current.update(() => {
      // Get the anchor node (current focus point of the selection)
      let anchorNode = $getNodeByKey(selectedNode);
      // Traverse upwards to find the table node
      while (anchorNode !== null) {
        if (anchorNode.__type === 'tablerow') {
          anchorRowNode = anchorNode;

          // check if this row has image inserted before
          let children = anchorNode.getFirstChild().getFirstChild();
          if (children.__type === 'image') {
            __handleDeleteFirstCell(refEditor, children.__key);
          }
          break;
        }
        anchorNode = anchorNode.getParent();
      }
      $setSelection(null);
    });

    __createTableImageCellNode(res, anchorRowNode, refEditor);
  }
  // }
};

const __handleDeleteFirstCell = (refEditor: any, nodeKey: NodeKey) => {
  (refEditor.current || refEditor).update(() => {
    if (nodeKey) {
      const selectionNode = $getNodeByKey(nodeKey);
      if (selectionNode) {
        const parentNode = selectionNode?.getParent();
        parentNode?.remove();
      }
    }
  });
};
// const __checkParagraphValidation = (text: string) => {
//   let finalParagraphs = '';
//   // Match pattern for the wrapped html
//   const regex = new RegExp(
//     /([^>]+)(ul|(h[1-5])|p|code|ol|div)(\W+)(.*?)<\/\2>/,
//     'gm'
//   );
//   // Replace the match pattern with special sign
//   let remainingText = text.replace(regex, '$@');

//   // Wrap the missing element with p tag
//   let filterText = remainingText.split('$@').filter((item: any) => item !== '');

//   let itemValue: { [key: string]: string } = {};
//   if (filterText.length > 0) {
//     Sentry.captureMessage(
//       `Translation job HTML Error format: ${text}`,
//       'debug'
//     );
//   }
//   filterText.forEach((item: string) => {
//     if (item !== '<br/>' && item !== '<br>') {
//       itemValue = {
//         [item]: `<p class="editor-paragraph">${item}</p>`,
//         ...itemValue,
//       };
//     }
//   });

//   // Replace the matching text with the updated one
//   let textValueUpdate = text;

//   for (let item in itemValue) {
//     textValueUpdate = textValueUpdate.replace(item, itemValue[item]);
//   }
//   finalParagraphs = finalParagraphs + textValueUpdate;

//   return finalParagraphs;
// };

const __checkClassName = (text: string) => {
  const className: { [key: string]: string } = {
    p: 'editor-paragraph', // top level elements, can't be nested
    h1: 'editor-heading-h1',
    h2: 'editor-heading-h2',
    h3: 'editor-heading-h3',
    h4: 'editor-heading-h4',
    h5: 'editor-heading-h5',
    h6: 'editor-heading-h6',
    ul: 'editor-list-ul',
    code: 'editor-code',
    img: 'editor-image',
  };

  for (let classItem in className) {
    const regex = new RegExp(
      // eslint-disable-next-line no-useless-escape
      `<(${classItem})(?!class\s*=\s*"[^"]*"\s*)>(.*?)`,
      'g'
    );

    text = text.replaceAll(
      regex,
      `<${classItem} class="${className[classItem]}">`
    );
    // replace code text to pre as lexical is accepting it like this
    if (/<\/?code>/g.test(text)) {
      text = text.replaceAll(/code>/g, 'pre>');
      text = text.replaceAll(/<code/g, '<pre');
    }
  }

  return text;
};

export const replaceTextLexical = (
  refEditor: any,
  textToMatch: { [key: string]: string }
) => {
  const sortObjectKey = Object.keys(textToMatch).sort(
    (a, b) => b.length - a.length
  );
  const rgx = new RegExp(`(${sortObjectKey.join('|')})`, 'gi');
  if (sortObjectKey.length > 0) {
    const exception: string[] = ['linebreak', 'paragraph'];
    if (refEditor.current) {
      refEditor.current.update(() => {
        const root = $getRoot();
        const nodes = $getAllNodes(root);
        for (const node of nodes) {
          if (exception.includes(node.__type) || node.__parent === 'root')
            continue;

          if (node.__type === 'text') {
            const nodeIndexs = __getNodeIndex(node, rgx);
            if (nodeIndexs) {
              __nodeSplit(nodeIndexs, node, rgx);
            } else {
              node.setStyle('background-color: rgb(220 226 230 / 3%)');
            }
          }
          // }
        }
      });
    }
    // }
  }
};

export const resetHighlight = (refEditor: any) => {
  const exception: string[] = ['linebreak', 'paragraph'];
  if (refEditor.current) {
    refEditor.current.update(() => {
      const root = $getRoot();
      const nodes = $getAllNodes(root);

      for (const node of nodes) {
        if (exception.includes(node.__type) || node.__parent === 'root')
          continue;
        if (node.__type === 'text') {
          // Remove all the background
          node.setStyle('background-color: rgb(220 226 230 / 3%)');
        }
      }
    });
  }
};

const __nodeSplit = (
  data: { resultIndex: number[]; nodeText: string },
  node: any,
  rgx: any
) => {
  const { resultIndex } = data;
  const splitNodes = node.splitText(...resultIndex);
  // let textValue = node.__text;
  // const { currentNode } = selectedTarget;
  let nodeText: string = '';
  node.setTextContent('');
  for (let i = 1; i < splitNodes.length; i++) {
    nodeText = splitNodes[i].__text;
    if (nodeText !== ':') {
      if (rgx.test(nodeText)) {
        splitNodes[i].setStyle(
          'background-color: #edc34775; padding: 2px; border-radius: 3px;'
        );
      } else {
        splitNodes[i].setStyle('background-color: rgb(220 226 230 / 3%)');
      }
      node.insertBefore(splitNodes[i]);
      // if (!firstTime) {
      // $getRoot().selectStart();
      // }
    }
  }
};

const __getNodeIndex = (node: any, rgx: any) => {
  let nodeText = node.__text;
  let check = nodeText.match(rgx);
  if (check?.length) {
    node = node.setTextContent(`:${nodeText}`);
    nodeText = `:${nodeText}`;
    const matches = nodeText.split(rgx);
    let resultIndex: any[] = [1];
    matches?.forEach((match: any) => {
      let checkMatch = match.match(rgx);
      if (checkMatch?.length) {
        let setIndex = nodeText.indexOf(
          match,
          resultIndex[resultIndex.length - 1]
        );
        resultIndex = [...resultIndex, setIndex, setIndex + match.length];
      }
    });
    return { resultIndex, nodeText };
  }
};

const __createDomAndUpdate = (
  targetText: string,
  refEditor: any,
  id: string
) => {
  const nodes = __createHtmlFromText(
    targetText.replaceAll('\n', '<br/>'),
    refEditor
  );
  $insertNodes(nodes);
  // Make the curoser start at the begining when first time translate
  // if (id === 'translation-input') {
  $getRoot()?.selectStart();
  // }
};

const __createHtmlFromText = (
  targetText: string,
  refEditor: any,
  paragraphFormat: ElementFormatType = ''
) => {
  // In the browser you can use the native DOMParser API to parse the HTML string.
  const parser = new DOMParser();
  const dom = parser.parseFromString(targetText, 'text/html');
  // Once you have the DOM instance it's easy to generate LexicalNodes.
  const nodes = $generateNodesFromDOM(refEditor.current, dom);
  // $createParagraphNode().setFormat(paragraphFormat);
  return nodes;
};

export function $getAllNodes(root: LexicalNode): Array<LexicalNode> {
  const nodes: any = [];
  let child: LexicalNode | null = root.getFirstChild();
  while (child !== null) {
    nodes.push(child);
    if ($isElementNode(child)) {
      const subChildrenNodes = $getAllNodes(child);
      nodes.push(...subChildrenNodes);
    }
    child = child.getNextSibling();
  }
  return nodes;
}

// export const currentSelected = (refEditor: any) => {
//   if (refEditor.current) {
//     const selection = refEditor?.current?.getEditorSate().read(() => {
//       return $getSelection();
//     });
//     if (selection?.focus?.key) {
//       const currentNode = $getNodeByKey(selection?.focus?.key);
//       return { selection, currentNode };
//     }
//     return null;
//   }
// };
// const __selectNode = (key: string) => {
//   const currentNode = $getNodeByKey(key);
//   const style = currentNode?.getStyle();
//   if (
//     style !== 'background-color: #edc34775; padding: 2px; border-radius: 3px;'
//   ) {
//     currentNode?.select(1, 1);
//   } else {
//     currentNode?.select();
//   }
// };

export const checkInputLength = (
  inputText: string,
  dispatch: any,
  userDetails: any,
  t: any
) => {
  if (inputText.length < 1) {
    dispatch(triggerAlert(t('error.minInputLength'), 'error'));
    return false;
  } else if (inputText.length > 10000) {
    dispatch(triggerAlert(t('error.inputlength'), 'error'));
    return false;
  } else if (
    userDetails &&
    userDetails?.quotaspent + inputText.length > userDetails?.quotalimit &&
    userDetails?.translatorlicence === true &&
    userDetails?.currentlicense !== 'Kostenfreies Paket'
  ) {
    // show quota alert if user is a translator
    dispatch(triggerAlert(t('error.quotaExceeded'), 'error'));
    return false;
  } else if (
    userDetails &&
    userDetails?.quotaspent + inputText.length > userDetails?.quotalimit
  ) {
    dispatch(triggerAlert(t('error.inputLengthLimitPremium'), 'error'));
    return false;
  } else {
    return true;
  }
};

export const heighlightSelection = (
  selection: any,
  refEditor: any,
  text: string,
  insideTextArea: boolean = true
) => {
  if (refEditor.current) {
    refEditor.current.update(() => {
      // let text = selection?.getTextContent();
      const root = $getRoot();
      const allNodes = $getAllNodes(root);
      const exception: string[] = ['linebreak', 'paragraph', 'list'];
      for (const node of allNodes) {
        if (exception.includes(node.__type) || node.__parent === 'root')
          continue;
        // Remove all the background
        if (node.__type === 'text') {
          node.setStyle('background-color: rgb(220 226 230 / 3%)');
        }
      }
      if (!isEmpty(text)) {
        // Clear the already highlighted text

        // select start this will always start from 0 to offset value
        let anchorValue = selection?.anchor;
        let anchorOfffset = anchorValue.offset;
        let anchoreKey = anchorValue.key;

        // select end this will always start from the offset value
        let focusVal = selection?.focus;
        let focusOffset = focusVal.offset;
        let focuseKey = focusVal.key;

        // Selection nodes
        let nodes = selection?.getNodes();
        // Check if the select from the same node
        if (nodes.length === 1) {
          // split the text
          let textSplit = nodes[0]?.splitText(...[focusOffset, anchorOfffset]);
          for (let item of textSplit) {
            if (item.getTextContent() === text) {
              item.setStyle(
                'background-color: #E3E9ED; padding: 0px; border-radius: 3px;'
              );
            }
          }
        }

        // Add condition depend if the pointer inside or outside textarea
        let checkTextContent = (node: any) =>
          !insideTextArea ? text.includes(node.__text) : true;

        // Check id the select includes different nodes
        if (nodes.length > 1) {
          // Loop over the node and check the key to split what match user selection
          for (let node of nodes) {
            // If line break skip it
            if (node.__type === 'text') {
              if (node.__key === anchoreKey && checkTextContent(node)) {
                let textSplit = node.splitText(...[anchorOfffset]);
                let indexItem = text.endsWith(textSplit[0].__text)
                  ? 0
                  : textSplit.length - 1;
                textSplit[indexItem].setStyle(
                  'background-color: #E3E9ED; padding: 0px; border-radius: 3px;'
                );
              } else if (node.__key === focuseKey && checkTextContent(node)) {
                let textSplit = node.splitText(...[focusOffset]);
                // check if select started from the bottom or the top
                let indexItem =
                  text.startsWith(textSplit[0].__text) ||
                  text.endsWith(textSplit[0].__text)
                    ? 0
                    : textSplit.length - 1;
                textSplit[indexItem].setStyle(
                  'background-color: #E3E9ED; padding: 0px; border-radius: 3px;'
                );
              } else if (
                node.__key !== anchoreKey &&
                node.__key !== focuseKey
              ) {
                node.setStyle(
                  'background-color: #E3E9ED; padding: 0px; border-radius: 3px;'
                );
              }
            }
          }
        }

        nodes[0]?.select();
      }
    });
  }
};
