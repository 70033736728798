import { languageType } from 'src/enums/languageType';

export const optionList = [
  // {
  //   name: 'history.headers.textType',
  //   value: '',
  // },
  {
    name: 'history.textTypes.lyric',
    value: 'story',
  },
  {
    name: 'history.textTypes.news',
    value: 'news',
  },
  {
    name: 'history.textTypes.correspondence',
    value: 'correspondence',
  },
  {
    name: 'history.textTypes.information',
    value: 'Information',
  },
  {
    name: 'history.textTypes.administration',
    value: 'admin',
  },
  // {
  //   name: 'history.textTypes.other',
  //   value: 'others',
  // },
];

export const textTypeFilterList: (t: any) => { id: string; value: string }[] = (
  t
) => [
  {
    id: 'none',
    value: '',
    text: t('none'),
  },
  {
    id: 'story',
    value: 'story',
    text: t('history.textTypes.lyric', { ns: 'easy_language' }),
  },
  {
    id: 'news',
    value: 'news',
    text: t('history.textTypes.news', { ns: 'easy_language' }),
  },
  {
    id: 'correspondence',
    value: 'correspondence',
    text: t('history.textTypes.correspondence', { ns: 'easy_language' }),
  },
  {
    id: 'information',
    value: 'information',
    text: t('history.textTypes.information', { ns: 'easy_language' }),
  },
  {
    id: 'admin',
    value: 'admin',
    text: t('history.textTypes.administration', { ns: 'easy_language' }),
  },
  {
    id: 'others',
    value: 'others',
    text: t('history.textTypes.other', { ns: 'easy_language' }),
  },
];

export const feedbackList = [
  // {
  //   name: 'history.feedback.giveFeedback',
  //   value: '',
  // },
  {
    name: 'history.feedback.positive',
    value: 'positive',
  },
  {
    name: 'history.feedback.adjusted',
    value: 'adjusted',
  },
  {
    name: 'history.feedback.negative',
    value: 'negative',
  },
];

export const feedbackFilterList: (t: any) => { id: string; value: string }[] = (
  t
) => [
  {
    id: 'none',
    value: '',
    text: t('none'),
  },
  {
    id: 'positive',
    value: 'positive',
    text: t('history.feedback.positive', { ns: 'easy_language' }),
  },
  {
    id: 'adjusted',
    value: 'adjusted',
    text: t('history.feedback.adjusted', { ns: 'easy_language' }),
  },
  {
    id: 'negative',
    value: 'negative',
    text: t('history.feedback.negative', { ns: 'easy_language' }),
  },
];

export const filterList = [
  {
    name: 'history.jobTask.all',
    value: languageType.none_lang,
  },
  {
    name: 'output.leichteSprache',
    value: languageType.easy_lang,
  },
  {
    name: 'output.einfacheSprache',
    value: languageType.plain_lang,
  },
];
