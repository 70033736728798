import TextField from '@mui/material/TextField';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import { Autocomplete, Grid } from '@mui/material';
import { isEmpty } from 'lodash';

type SearchInputCompProps = {
  handleSubmit: (value: string) => void;
  loading: boolean;
  placeholder?: string;
  inputId?: string;
  buttonId?: string;
  containerGrid?: number;
};

const SearchInputComp: FC<SearchInputCompProps> = ({
  handleSubmit,
  loading,
  placeholder,
  inputId = '',
  buttonId = '',
  containerGrid = 12,
}) => {
  const { t } = useTranslation(['glossary', 'common']);
  const [value, setValue] = useState<string>('');
  const handleChange = (
    event: React.SyntheticEvent,
    value: string,
    reason: 'input' | 'reset' | 'clear'
  ) => {
    setValue(value);
    if (reason === 'clear' || isEmpty(value)) {
      handleSubmit('');
    }
  };
  return (
    <Grid
      item
      container
      xs={containerGrid}
      justifyContent={'space-between'}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !isEmpty(value)) {
          handleSubmit(value);
        }
      }}
    >
      <Grid
        item
        xs={7.7}
      >
        <Autocomplete
          id={inputId}
          freeSolo={true}
          renderInput={(params) => (
            <TextField
              {...params}
              label={placeholder}
              size="small"
            />
          )}
          autoComplete={false}
          inputValue={value}
          options={[]}
          onInputChange={handleChange}
        />
      </Grid>
      <Grid
        item
        xs={4}
      >
        <LoadingButton
          size="small"
          onClick={() => handleSubmit(value)}
          loading={loading}
          variant="contained"
          id={buttonId}
          sx={{
            textTransform: 'none',
            fontSize: '1rem',
            p: '0.375rem 0.75rem',
            width: '100%',
            height: '100%',
          }}
          disabled={isEmpty(value)}
        >
          {t('search', { ns: 'common' })}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default SearchInputComp;
