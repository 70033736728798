import { createSelector } from 'reselect';
import { ImageUploadItem } from 'src/types/imageUpload.types';

export interface UploadImageReducer {
  loading: boolean;
  imageList: null | {
    ownerList: null | { [key: string]: ImageUploadItem };
    otherList: null | { [key: string]: ImageUploadItem };
  };
  uploadedImageList: {
    [key: string]: { msg: string; status: 'error' | 'success'; id: string };
  };
  tempImageList: { [key: string]: string };
  heightlight: boolean;
}
interface RootState {
  imageUpload: UploadImageReducer;
}

// This used to memorize the selector value
export const UploadImageSelector = createSelector(
  (state: RootState) => state.imageUpload,
  (config: UploadImageReducer) => {
    return {
      loading: config.loading,
      imageList: config.imageList,
      uploadedImageList: config.uploadedImageList,
      tempImageList: config.tempImageList,
      heightlight: config.heightlight,
    };
  }
);
