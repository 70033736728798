import imageUploadService from 'src/services/imageUpload.service';
import { appMessageHandling } from '../../utils/errorHandler';
import {
  // GET_ALL_UPLOADED_IMAGE,
  LOADING,
  SEARCH_UPLOADED_IMAGE,
  STOP_LOADING,
  UPLOAD_IMAGE,
  UPLOAD_LIST_IMAGE,
  RESET_IMAGE_UPLOAD_STATE,
  SET_UPLOAD_IMAGE_LIST,
  FEEDBACK_IMAGE,
  DELETE_IMAGE,
} from './types';
import { isEmpty } from 'lodash';
import Sentry from '../../config/sentryConfig';
import {
  heighlightSelection,
  resetHighlight,
} from 'src/components/TextareaField/helper';
import { outputEditor } from 'src/components/TextareaField/TextareaField';
import { updateGalleryState } from '../myGallery/actions';

export const addImageUpload =
  (data: File[], uploadedFrom: 'translation' | 'gallery' = 'translation') =>
  async (dispatch: any, getState: any) => {
    let count = 0;
    // delete the file from the list if it was already uploaded before
    dispatch(updateFileList([...data]));

    // Make the req to upload the file
    const sentRequest = async (file: File) => {
      try {
        const formData = new FormData();
        formData.append('file', file);
        const res = await imageUploadService.uploadImageApi(formData);
        dispatch({
          type: UPLOAD_IMAGE,
          payload: {
            status: 'success',
            id: file.name,
            msg: 'successUpload',
          },
        });
        if (uploadedFrom === 'gallery') {
          dispatch(updateGalleryState(res.data.data.uuid));
        }
      } catch (e: any) {
        let msg = e?.response?.data?.detail ?? 'errorUpload';
        if (
          msg === 'The user has already mapping to the image in the database'
        ) {
          msg = 'alreadyUploaded';
        } else if (
          msg === 'Images can only have .jpg, .jpeg or .png extension'
        ) {
          msg = 'wrongExtension';
        }
        Sentry.captureMessage(`image upload Error: ${e}`, 'error');
        return dispatch({
          type: UPLOAD_IMAGE,
          payload: {
            msg,
            status: 'error',
            id: file.name,
          },
        });
      } finally {
        if (count < data.length - 1) {
          count += 1;
          sentRequest(data[count]);
        }
      }
    };
    sentRequest(data[count]);
  };

// todo will update this later with the correct api to fetch all the images
// export const getAllUploadedImages = () => async (dispatch: any) => {
//   try {
//     const res = await imageUploadService.getUploadedImageApi();
//     dispatch({
//       type: GET_ALL_UPLOADED_IMAGE,
//       payload: res.data,
//     });
//   } catch (e: any) {
//     return appMessageHandling(
//       dispatch,
//       e.detail || 'error.get_uploaded_image_error',
//       'error'
//     );
//   }
// };

export const feedbackImageUpload =
  (id: string, feedback: 0 | 1 | 2, name: number, isOwner: boolean) =>
  async (dispatch: any) => {
    try {
      const res = await imageUploadService.feedbackImageApi(id, feedback);
      dispatch({
        type: FEEDBACK_IMAGE,
        payload: { ...res.data, name, isOwner },
      });
    } catch (e: any) {
      Sentry.captureMessage(`image upload feedback Error: ${e}`, 'error');
      return appMessageHandling(
        dispatch,
        e.detail || 'error.general_error',
        'error'
      );
    }
  };
export const deleteUploadedImage =
  (id: string, name: number, isOwner: boolean) => async (dispatch: any) => {
    try {
      await imageUploadService.deleteUploadedImageApi(id);
      dispatch({
        type: DELETE_IMAGE,
        payload: { id, name, isOwner },
      });
    } catch (e: any) {
      Sentry.captureMessage(`image upload delete Error: ${e}`, 'error');
      return appMessageHandling(
        dispatch,
        e.detail || 'error.general_error',
        'error'
      );
    }
  };

export const downloadUploadedImage =
  (id: string) => (dispatch: any, getState: any) => {
    const userEmail = getState().user.userDetails.email;
    const link = document.createElement('a');
    link.href = `${process.env.REACT_APP_DJANGO_BACKEND_DOMAIN}images/download/${id}/${userEmail}`;
    link.setAttribute('download', id);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

export const searchUploadedImages =
  (
    value: string,
    extract_keywords: boolean = false,
    heightlight: boolean = false,
    text?: string,
    selectionVal?: any,
    outputRefEditor?: any,
    insideTextArea?: boolean
  ) =>
  async (dispatch: any, getState: any) => {
    let stateVal = getState().appSetting.sidebarTab;
    if (stateVal === 3 && !isEmpty(value)) {
      // That mean user select two text from two differen paragraphs which is not valid
      if (
        selectionVal?.anchor?.type === 'element' ||
        selectionVal?.focus?.type === 'element'
      ) {
        return;
      }

      if (heightlight && text) {
        heighlightSelection(
          selectionVal,
          outputRefEditor,
          text,
          insideTextArea
        );
      }
      try {
        dispatch(loading());
        let res: any = { data: null };
        if (!isEmpty(value)) {
          res = await imageUploadService.searchUploadedImageApi(
            value,
            extract_keywords
          );
        }
        dispatch({
          type: SEARCH_UPLOADED_IMAGE,
          payload: { list: res?.data?.data ?? null, heightlight },
        });
      } catch (e: any) {
        Sentry.captureMessage(`image upload search Error: ${e}`, 'error');
        return appMessageHandling(
          dispatch,
          e?.response?.data?.detail ?? 'error.general_error',
          'error'
        );
      } finally {
        dispatch(stopLoading());
      }
    } else if (isEmpty(value)) {
      resetHighlight(outputEditor);
      // return dispatch(emptyStateValueImageUpload({ imageList: null }));
    }
  };

export const updateFileList = (data: File[]) => (dispatch: any) => {
  data?.forEach((item: File) => {
    dispatch({
      type: UPLOAD_LIST_IMAGE,
      payload: item.name,
    });
  });
};

export const loading = () => (dispatch: any) => {
  return dispatch({
    type: LOADING,
  });
};

export const stopLoading = () => (dispatch: any) => {
  return dispatch({
    type: STOP_LOADING,
  });
};

export const emptyStateValueImageUpload = (value: any) => (dispatch: any) => {
  return dispatch({
    type: RESET_IMAGE_UPLOAD_STATE,
    payload: value,
  });
};

export const setImageUploadList =
  (value: { [key: string]: string }) => (dispatch: any) => {
    return dispatch({
      type: SET_UPLOAD_IMAGE_LIST,
      payload: value,
    });
  };
