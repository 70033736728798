import React, { useEffect } from 'react';

import Cookies from 'universal-cookie';
import { Container, Row, Col } from 'react-bootstrap';
import ProductCard from './ProductCard';
import ToggleSwitch from '../../widgets/ToggleSwitch';
import {
  useNavigate,
  useNavigationType,
  useLocation,
  NavigationType,
} from 'react-router-dom';
import HelpButton from './HelpButton';
import styles from './ThankYouPage.module.css';

const ThankYouPage = () => {
  const cookies = new Cookies();
  const history = useNavigate();
  const historyUpdate = useLocation();
  const navigationType = useNavigationType();

  if (!cookies.get('selectedProduct')) {
    history('/summ-products');
  }

  const product = cookies.get('selectedProduct')
    ? cookies.get('selectedProduct')
    : {};
  const checked = cookies.get('selectedProduct')
    ? product.licencePeriod === 'Jährlich'
    : false;

  useEffect(() => {
    return () => {
      // listen
      if (navigationType === NavigationType.Pop) {
        history('/thankyou');
      }
      return;
    };
  }, [history, historyUpdate, navigationType]);

  return cookies.get('selectedProduct') ? (
    <>
      <Container className="mb-5">
        <Row>
          <Col
            md={5}
            className={'mt-5 ' + styles['productcard-div']}
          >
            <div className={styles.toggleDiv}>
              <ToggleSwitch
                leftLabel={'monatlich'}
                rightLabel={'jährlich'}
                rightStyle={{
                  fontWeight: 'bold',
                }}
                checked={checked}
              />
            </div>
            <div className={styles.productCard + ' mt-4'}>
              <ProductCard
                product={product}
                licencePeriod={product.licencePeriod}
              />
            </div>
          </Col>
          <Col md={6}>
            <h1 className="mt-5">Vielen Dank für Ihre Bestellung!</h1>
            <div className="text-left mt-5">
              <p className="font-weight-bold">Wie geht es jetzt weiter?</p>
              <p className="font-weight-bold">
                Sie erhalten von uns in wenigen Augenblicken eine
                Bestätigungs-E-Mail mit einer Zusammenfassung Ihrer Bestellung.
              </p>
              <p>
                Nach einer kurzen Prüfung Ihrer Bestellung schalten wir dann
                innerhalb der nächsten 48 Stunden Ihren Account frei. Über die
                Freischaltung informieren wir Sie ebenfalls noch einmal per
                E-Mail.
              </p>
              <p>
                Sobald Sie diese E-Mail erhalten haben, können Sie den Download
                und die Installation durchführen und mit der Nutzung von SUMM
                beginnen.
              </p>
              <p>
                <span className="font-weight-bold">
                  Sie haben noch offene Fragen?
                </span>{' '}
                Melden Sie sich gern unter{' '}
                <a href="mailto:service@summ-ai.com">service@summ-ai.com</a>{' '}
                oder unter +49 178 3674624.
              </p>
            </div>
            <p className="mt-5 font-weight-bold">
              Sie können diese Seite jetzt schließen.
            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col
            md={11}
            className="text-right mb-5"
          >
            <HelpButton />
          </Col>
        </Row>
      </Container>
    </>
  ) : (
    <></>
  );
};

export default ThankYouPage;
