import { useMemo, useRef } from 'react';
import { useDropzone, FileRejection } from 'react-dropzone';
import Grid from '@mui/material/Grid';
import DragDropComp from './DragDropComp';
import FileUploading from './FileUploading';
import { Typography } from '@mui/material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  addImageUpload,
  setImageUploadList,
} from 'src/redux/imageUpload/actions';
import { useTranslation } from 'react-i18next';
import { UploadImageSelector } from 'src/redux/imageUpload/selector';
import { appMessageHandling } from 'src/utils/errorHandler';

type Props = {};

const ImageUpload = (props: Props) => {
  const { t } = useTranslation('easy_language');
  const dispatch: any = useDispatch();
  const tempImageList = useSelector(
    (state: any) => UploadImageSelector(state).tempImageList,
    shallowEqual
  );

  const { getRootProps, getInputProps, isDragAccept, isDragReject, open } =
    useDropzone({
      accept: {
        'image/png': ['.png'],
        'image/jpg': ['.jpg'],
        'image/jpeg': ['.jpeg'],
      },
      noClick: true,
      noKeyboard: true,
      // maxFiles: 10,
      maxSize: 10 * 1024 * 1024, // file isze must not exceed 10mb,
      onDropAccepted: (acceptedFiles) => {
        dispatch(addImageUpload(acceptedFiles));
        let temFileAccepted = {};
        acceptedFiles.forEach((file: File) => {
          temFileAccepted = {
            ...temFileAccepted,
            [file.name]: { name: file.name },
          };
        });
        dispatch(setImageUploadList({ ...tempImageList, ...temFileAccepted }));
      },
      onDropRejected: (files, e) => {
        files.map((file: FileRejection) =>
          appMessageHandling(
            dispatch,
            file.errors[0].code === 'file-too-large'
              ? 'File is too large'
              : file.errors[0].message,
            'error'
          )
        );
      },
    });
  const { ref } = getRootProps();
  const dragRef: any = useRef(null);

  const files = useMemo(
    () =>
      Object.values(tempImageList).map((file: any, index: number) => {
        return (
          <FileUploading
            key={file.path + index}
            file={file}
          />
        );
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tempImageList]
  );
  return (
    <Grid
      item
      container
      xs={12}
      ref={ref}
      spacing={1}
    >
      <Grid
        item
        container
        xs={12}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <DragDropComp
          isDragAccept={isDragAccept}
          isDragReject={isDragReject}
          open={open}
          dragRef={dragRef}
        />
      </Grid>

      {Object.values(tempImageList).length > 0 && (
        <Grid
          item
          container
          xs={12}
        >
          <Grid
            item
            xs={12}
          >
            <Typography
              variant="body2"
              gutterBottom
              sx={{
                textWrap: 'pretty',
                textAlign: 'left',
              }}
            >
              {t('ListUploadTitle')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
          >
            {files}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ImageUpload;
