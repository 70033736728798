export const clearLocalSessionStorage = (element: string) => {
  sessionStorage.removeItem(element);
};

export const clearSessionandLocalStorge = () => {
  // store all locastorage key
  let allItems: string[] = [];
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key) allItems.push(key);
  }
  allItems?.forEach((key) => {
    if (
      ![
        'switchInput-translation-output',
        'IN_PURCHASE_FLOW',
        'outputLanguageType',
        'i18nextLng',
        'switchInput-translation-input',
        'appversion',
        'newline.easy',
        'newline.plain',
        'separator.plain',
        'separator.easy',
        'signup_form',
      ].includes(key)
    )
      localStorage.removeItem(key);
  });

  sessionStorage.clear();
};
